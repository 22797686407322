import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { useDebounce } from 'use-debounce';
import SectionContainerLayout from '../../components/section-container-layout/section-container-layout';
import { CardMedia, Grid, Typography, Box, Button, useMediaQuery, Divider } from '@material-ui/core';
import { Link } from 'gatsby';
import ImageContainerBorder from '../../components/image-container/image-container-border';
import Fade from 'react-reveal/Fade';
import CustomLink from '../../components/custom-link';
import colors from '../../constants/colors';
import Scrollbar from '../../components/scrollbar';
import intro from '../../images/initiatives/GMIS.png';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import ScrollbarAlwaysOn from '../../components/scrollbar/scrollbar-always-on';

// const fillerObjects = [{title: "title", description:"description", image: "/src/images/default.jpg", link:"yes"},
// {title: "title", description:"description", image: "/src/images/default.jpg", link:"yes"},
// {title: "title", description:"description", image: "/src/images/default.jpg", link: ''}]

const InitiativesPage = ({ pageContext: { _initiativesArray } }) => {
  const [hasResult, setHasResult] = useState(true);
  const [initiatives, setInitiatives] = useState(_initiativesArray);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isShowPartners, setIsShowPartners] = useState(true);

  let _partnersCount = 0;

  useEffect(() => {
    let _allInitiatives = initiatives;
    if (_allInitiatives.length > 0) {
      setHasResult(true);
    } else {
      setHasResult(false);
    }
    return () => {};
  });

  return (
    <Layout>
      <SEO lang='en' title='Initiatives | Global Manufacturing & Industrialisation Summit | GMIS2022' />
      <SectionContainerLayout title='GMIS Initiatives' baseLink='/initiatives'>
        {/* Intro */}
        <Grid item xs={12}>
          <Typography variant='h5' color='primary' gutterBottom className='text-uppercase'>
            <Box fontWeight='fontWeightBold' mt={6}>
              MAKE A DIFFERENCE
            </Box>
          </Typography>

          <Divider />
        </Grid>

        <>
          <Grid container spacing={5} style={{ paddingTop: 10 }}>
            {isMobile ? (
              <>
                <Scrollbar maxHeight='400px' width='100%'>
                  <div>
                    At the Global Manufacturing and Industrialisation Summit, we are determined to make a positive
                    global impact. Every year, in partnership with the host country in which the summit took place and
                    our global ecosystem of partners, we conceptualise and launch a new initiative with the purpose of
                    making a difference. The initiatives are inspired and guided by the United Nations Sustainable
                    Development Goals (SDGs), and aim to enhance public-private partnerships, capitalise on the
                    transformative potential of Fourth Industrial Revolution (4IR) technologies, share and exchange
                    knowledge and expertise amongst our international community and partners, and overall contribute to
                    making our world more sustainable, safe and equitable.
                  </div>
                </Scrollbar>
                <Grid item xs={12} p={2} style={{ paddingTop: '30px' }}>
                  <Fade bottom>
                    <ImageContainerBorder height={200} component='img' image={intro} />
                  </Fade>
                </Grid>
              </>
            ) : (
              <>
                <Grid item lg={6} md={6} xs={12} p={2} style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                  <Scrollbar maxHeight='400px' width='100%'>
                    <div>
                      At the Global Manufacturing and Industrialisation Summit, we are determined to make a positive
                      global impact. Every year, in partnership with the host country in which the summit took place and
                      our global ecosystem of partners, we conceptualise and launch a new initiative with the purpose of
                      making a difference. The initiatives are inspired and guided by the United Nations Sustainable
                      Development Goals (SDGs), and aim to enhance public-private partnerships, capitalise on the
                      transformative potential of Fourth Industrial Revolution (4IR) technologies, share and exchange
                      knowledge and expertise amongst our international community and partners, and overall contribute
                      to making our world more sustainable, safe and equitable.
                    </div>
                  </Scrollbar>
                </Grid>
                <Grid item lg={6} md={6} xs={12} p={2} style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                  <Fade bottom>
                    <ImageContainerBorder height={200} component='img' image={intro} />
                  </Fade>
                </Grid>
              </>
            )}
          </Grid>
        </>

        {hasResult
          ? _initiativesArray.map((_initiative, initiativeIndex) => (
              <>
                <Grid key={initiativeIndex} container spacing={5} style={{ paddingTop: 10 }}>
                  {isMobile ? (
                    <>
                      <Grid item xs={12} p={2} style={{ paddingTop: '30px' }}>
                        <Fade bottom>
                          <ImageContainerBorder
                            height={200}
                            component='img'
                            image={`${process.env.GATSBY_CMS_API_URL}/static/${_initiative.file.code}`}
                            alt={_initiative.title || '#GMIS2021'}
                          />
                        </Fade>
                      </Grid>
                      <Grid item xs={12} p={2} style={{ paddingBottom: '30px' }}>
                        <Fade bottom>
                          <Typography color='textPrimary' variant='h6' gutterBottom>
                            <b>{_initiative.title.toUpperCase()}</b>
                          </Typography>
                          <Typography color='textSecondary' variant='h6' gutterBottom>
                            {_initiative.subtitle}
                          </Typography>
                          <Scrollbar maxHeight='400px' width='100%'>
                            <div dangerouslySetInnerHTML={{ __html: _initiative.description }} />
                          </Scrollbar>
                        </Fade>
                        {_initiative.link !== null && _initiative.link !== '' ? (
                          <Box my={2}>
                            <Fade bottom>
                              <CustomLink
                                external
                                linkUrl={_initiative.link}
                                linkText='CHECK OUT THE WEBSITE'
                                type='2'
                              />
                            </Fade>
                          </Box>
                        ) : null}
                        {initiativeIndex + 1 < _initiativesArray.length ? <Divider /> : null}
                      </Grid>

                      <Grid item xs={12}>
                        {_initiative.initiativePartners && _initiative.initiativePartners.length > 0 && isShowPartners && (
                          <SectionContainerLayoutWithFilter
                            title={`${_initiative.title.toUpperCase()} INITIATIVE PARTNERS`}
                            baseLink='/partners'>
                            <ScrollbarAlwaysOn width='100%' height='100%' showArrows>
                              <Box display='flex'>
                                {_initiative.initiativePartners.map((_partner) => {
                                  if (_partner) {
                                    return (
                                      <>
                                        <Box key={_partner.code} width={150} mx={1} mb={2}>
                                          <img
                                            width='150px'
                                            src={
                                              _partner.file &&
                                              _partner.file.code &&
                                              `${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`
                                            }
                                            alt='category'
                                          />
                                        </Box>
                                      </>
                                    );
                                  } else {
                                    _partnersCount++;
                                    if (_partnersCount === _initiative.initiativePartners.length) {
                                      setIsShowPartners(false);
                                    }
                                  }
                                })}
                              </Box>
                            </ScrollbarAlwaysOn>
                          </SectionContainerLayoutWithFilter>
                        )}
                      </Grid>
                    </>
                  ) : initiativeIndex % 2 === 0 ? (
                    <>
                      <Grid item lg={6} md={6} xs={12} p={2} style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                        <Fade bottom>
                          <ImageContainerBorder
                            height={200}
                            component='img'
                            image={`${process.env.GATSBY_CMS_API_URL}/static/${_initiative.file.code}`}
                            alt={_initiative.title || '#GMIS2021'}
                          />
                        </Fade>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} p={2} style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                        <Fade bottom>
                          <Typography color='textPrimary' variant='h6' gutterBottom>
                            <b>{_initiative.title.toUpperCase()}</b>
                          </Typography>
                          <Typography color='textSecondary' variant='h6' gutterBottom>
                            {_initiative.subtitle}
                          </Typography>
                          <Scrollbar maxHeight='280px' width='100%'>
                            <div
                              style={{ maxHeight: '500px' }}
                              dangerouslySetInnerHTML={{ __html: _initiative.description }}
                            />
                          </Scrollbar>
                        </Fade>
                        {_initiative.link !== null && _initiative.link !== '' ? (
                          <Box my={2}>
                            <Fade bottom>
                              <CustomLink
                                external
                                linkUrl={_initiative.link}
                                linkText='CHECK OUT THE WEBSITE'
                                type='2'
                              />
                            </Fade>
                          </Box>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        {_initiative.initiativePartners && _initiative.initiativePartners.length > 0 && isShowPartners && (
                          <SectionContainerLayoutWithFilter
                            title={`${_initiative.title.toUpperCase()} PARTNERS`}
                            baseLink='/partners'>
                            <ScrollbarAlwaysOn width='100%' height='100%' showArrows>
                              <Box display='flex'>
                                {_initiative.initiativePartners.map((_partner) => {
                                  if (_partner) {
                                    return (
                                      <>
                                        <Box key={_partner.code} width={150} mx={1} mb={2}>
                                          <img
                                            width='150px'
                                            src={
                                              _partner.file &&
                                              _partner.file.code &&
                                              `${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`
                                            }
                                            alt='category'
                                          />
                                        </Box>
                                      </>
                                    );
                                  } else {
                                    _partnersCount++;
                                    if (_partnersCount === _initiative.initiativePartners.length) {
                                      setIsShowPartners(false);
                                    }
                                  }
                                })}
                              </Box>
                            </ScrollbarAlwaysOn>
                          </SectionContainerLayoutWithFilter>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item lg={6} md={6} xs={12} p={2} style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                        <Fade bottom>
                          <Typography color='textPrimary' variant='h6' gutterBottom>
                            <b> {_initiative.title.toUpperCase()} </b>
                          </Typography>
                          <Typography color='textSecondary' variant='h6' gutterBottom>
                            {_initiative.subtitle}
                          </Typography>
                          <Scrollbar maxHeight='280px' width='100%'>
                            <div dangerouslySetInnerHTML={{ __html: _initiative.description }} />
                          </Scrollbar>
                        </Fade>
                        {_initiative.link !== null && _initiative.link !== '' ? (
                          <Box my={2}>
                            <Fade bottom>
                              <CustomLink
                                external
                                linkUrl={_initiative.link}
                                linkText='CHECK OUT THE WEBSITE'
                                type='2'
                              />
                            </Fade>
                          </Box>
                        ) : null}
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} p={2} style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                        <Fade bottom>
                          <ImageContainerBorder
                            height={200}
                            component='img'
                            image={`${process.env.GATSBY_CMS_API_URL}/static/${_initiative.file.code}`}
                            alt={_initiative.title || '#GMIS2021'}
                          />
                        </Fade>
                      </Grid>
                      <Grid item xs={12}>
                        {_initiative.initiativePartners && _initiative.initiativePartners.length > 0 && isShowPartners && (
                          <SectionContainerLayoutWithFilter
                            title={`${_initiative.title.toUpperCase()} INITIATIVE PARTNERS`}
                            baseLink='/partners'>
                            <ScrollbarAlwaysOn width='100%' height='100%' showArrows>
                              <Box display='flex'>
                                {_initiative.initiativePartners.map((_partner) => {
                                  if (_partner) {
                                    return (
                                      <>
                                        <Box key={_partner.code} width={150} mx={1} mb={2}>
                                          <img
                                            width='150px'
                                            src={
                                              _partner.file &&
                                              _partner.file.code &&
                                              `${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`
                                            }
                                            alt='category'
                                          />
                                        </Box>
                                      </>
                                    );
                                  } else {
                                    _partnersCount++;
                                    if (_partnersCount === _initiative.initiativePartners.length) {
                                      setIsShowPartners(false);
                                    }
                                  }
                                })}
                              </Box>
                            </ScrollbarAlwaysOn>
                          </SectionContainerLayoutWithFilter>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            ))
          : null}
      </SectionContainerLayout>
    </Layout>
  );
};

export default InitiativesPage;
